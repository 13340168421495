body{
  background-color: rgb(255, 255, 255);
  margin: 0;
}
.containerNavbar{
  background-color: rgb(122, 121, 121);
  color: rgb(255, 254, 253);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}
.containerNavbar span{
  margin-left: 15px;
}
.containerNavbar h3{
  margin: 10px;
}

.cardClass{
  margin: 10px;
}
.cardClass img{
  filter: brightness(1.09) contrast(1.05)
}
.card_price{
  position: absolute;
  transform: translate(160px,0px);
  font-size: 20px;
  color: rgb(29, 29, 29);
  font-weight: 500;
}
.cardStyle{
  box-shadow: 0px 0px 8px rgba(95, 95, 95, 0.342);
}
.boxElementos{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.cardClass p{
    line-height: 1.2; /* ajuste o valor para o valor desejado */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* número de linhas desejado */
    -webkit-box-orient: vertical;
}